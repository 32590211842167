<template>
  <div class="popup_wrap" style="width:800px; height:520px;">
    <button
      class="layer_close"
      @click="layerClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        파일첨부
      </h1>
      <div class="content_box">
        <DEXT5Upload
          ref="dextupload"
          id="dextupload1"
          runtimes="html5"
          width="100%"
          height="200px"
          :single="singleFileYn"
          :category="false"
          :file-info="parentInfo"
        />
      </div>
    </div>
    <div class="mt10 text_center">
      <a class="button blue lg" @click="layerClose()">닫기</a>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/DEXT5Upload'

export default {
  name: 'AdminUploadPop',
  components: { DEXT5Upload },
  props: {
    singleFileYn: {
      type: Boolean,
      default: true
    },
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    setFileInfo: function (parentInfo) {
      let $vm = this

      if (this.$refs.dextupload !== undefined) {
        this.$refs.dextupload.setFileInfo(parentInfo)
      }
    },
    layerClose: function () {
      let file = this.$refs.dextupload.getFileInfo()
      this.$refs.dextupload.ResetDext()
      this.$emit('set-dext-file-info', file)
      this.$emit('close', 'pop2')
    }
  }
}
</script>
